export function init() {
    hideMessage();
    loadDatatable();
}

/**
 * nascondiamo il messaggio di successo nel cms
 */
function hideMessage(){
    $(".session-message").delay(3500).fadeOut();
}

/**
 * carichiamo la datatable
 */
function loadDatatable() {
    var table = $('.data-table').DataTable({
        "pageLength": 25,
        "sorting": [],
        "language": {
            "url": "/api/lang/pagination/datatable"
        },
        "autoWidth": false,
        "responsive": false,
        "columnDefs":  [
            { "targets": 'no-sort', "orderable": false }
        ]
    });

    $('.data-table tbody').on('click', 'a.delete', function () {
        if (confirm('Sei sicuro di procedere con l\'eliminazione?')) {
            var _this = $(this);

            var id = _this.data('id');

            var url = _this.data('url');

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type: 'DELETE',
                url: url,
                data: {
                    id: id
                },
                dataType: 'json',
                success: function success(result) {
                    if (result['success']) {
                        table.row($(_this).parents('tr')).remove().draw();
                    }

                    if (result['title'] !== undefined) {
                        alert(result['title']);
                    }
                }
            });
        }
    });

    $('.data-table tbody').on('click', 'input.breakfast-switch', function () {
        var _this = $(this);

        var id = _this.data('id');

        var url = _this.data('url');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'PUT',
            url: url,
            data: {
                id: id,
                value: _this.prop('checked')
            },
            dataType: 'json',
            success: function success(result) {
                if (!result['success']) {
                    _this.prop('checked', !_this.prop('checked'))
                }
            },
            error: function error() {
                _this.prop('checked', !_this.prop('checked'))
            }
        });
    });

    $('.data-table tbody').on('click', 'input.snack-switch', function () {
        var _this = $(this);

        var id = _this.data('id');

        var url = _this.data('url');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'PUT',
            url: url,
            data: {
                id: id,
                value: _this.prop('checked')
            },
            dataType: 'json',
            success: function success(result) {
                if (!result['success']) {
                    _this.prop('checked', !_this.prop('checked'))
                }
            },
            error: function error() {
                _this.prop('checked', !_this.prop('checked'))
            }
        });
    });
}